<template>
  <div>
    <TableFilters :filter-id="filterId" @filter-change="filterId = $event" />

    <a-divider />

    <a-button
      type="primary"
      :style="{ marginBottom: '15px' }"
      @click="$router.push({ name: 'filter-values-new' }).catch(_err => {})"
    >
      Создать новую опцию
    </a-button>

    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :scroll="{ x: 1500 }"
      :row-key="record => record.id"
      :pagination="{ current: pageNumber, total: filterValuesTotal }"
      :data-source="filterValues"
      @change="handleTableChange"
    >
      <div
        slot="is_active"
        slot-scope="record"
        style="display: flex; justify-content: center"
      >
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          :title="
            `Вы уверены, что хотите ${
              record.is_active ? 'отключить' : 'включить'
            } опцию?`
          "
          @confirm="updateFilterValue(record, 'is_active')"
        >
          <a-checkbox v-model="record.is_active"></a-checkbox>
        </a-popconfirm>
      </div>

      <span
        slot="actions"
        slot-scope="record"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <a-button
          icon="edit"
          title="Редактировать"
          type="primary"
          @click="
            $router
              .push({
                name: 'filter-values-edit',
                params: { id: record.id },
              })
              .catch(_err => {})
          "
        />
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          title="Вы уверены что хотите удалить эту опцию?"
          @confirm="deleteFilterValue(record)"
        >
          <a-button
            :style="{ marginLeft: '15px' }"
            type="danger"
            title="Удалить"
            icon="delete"
          />
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>
<script>
import columns from './table_columns'
import TableFilters from './TableFilters.vue'
import { debounce } from '@/utils'
import {
  FILTER_VALUES,
  GET_FILTER_VALUES,
  DELETE_FILTER_VALUE,
  FILTER_VALUES_TOTAL,
  UPDATE_FILTER_VALUE,
} from '@/store/types'

export default {
  components: { TableFilters },

  data() {
    return {
      columns,
      loading: false,
      filterId: 'all',
      pageNumber: 1,
    }
  },

  computed: {
    filterValues() {
      return this.$store.getters[FILTER_VALUES]
    },
    filterValuesTotal() {
      return this.$store.getters[FILTER_VALUES_TOTAL]
    },
  },

  watch: {
    filterId(value) {
      this.pageNumber = 1
      this.$router
        .replace({
          name: 'filters-values',
          query: {
            ...this.$route.query,
            filterId: Number.isNaN(Number(value)) ? value : Number(value),
            pageNumber: 1,
          },
        })
        .catch(err => err)

      this.getFilterValues()
    },
  },

  created() {
    let availableFilterId = this.$route.query.filterId || this.filterId

    this.filterId = Number.isNaN(Number(availableFilterId))
      ? availableFilterId
      : Number(availableFilterId)

    this.getFilterValues()
  },

  methods: {
    getFilterValues: debounce(function() {
      const { filterId, pageNumber } = this

      this.$store.dispatch(GET_FILTER_VALUES, {
        filterId,
        pageNumber,
      })
    }, 300),

    handleTableChange(pagination) {
      this.$router.replace({
        name: 'filters-values',
        query: { ...this.$route.query, pageNumber: pagination.current },
      })

      this.pageNumber = pagination.current

      this.getFilterValues()
    },

    updateFilterValue(filterValue, field) {
      this.$store.dispatch(UPDATE_FILTER_VALUE, {
        ...filterValue,
        [field]: !filterValue[field],
      })
    },

    deleteFilterValue(filterValue) {
      this.$store.dispatch(DELETE_FILTER_VALUE, filterValue)
    },
  },
}
</script>
