<template>
  <a-row>
    <a-col :span="6">
      <a-select
        :value="filterId"
        :style="{ width: '100%' }"
        required
        placeholder="Выберите фильтр"
        @change="$emit('filter-change', $event)"
      >
        <a-select-option key="all" value="all">
          <b>Все фильтры</b>
        </a-select-option>
        <a-select-option
          v-for="(filter, index) in filters"
          :key="index"
          :value="filter.id"
        >
          {{ filter.name }}
        </a-select-option>
      </a-select>
    </a-col>
  </a-row>
</template>

<script>
import axios from '@/axios'
import { showNotification } from '@/utils'
export default {
  props: {
    filterId: {
      type: [Number, String],
      default: 'all',
    },
  },

  data() {
    return {
      filters: [],
    }
  },

  created() {
    this.getFilters()
  },

  methods: {
    async getFilters() {
      try {
        const { data } = await axios.get('/filters')

        this.filters = data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
